/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// You can delete this file if you're not using it
//import "./node_modules/twin.macro/node_modules/tailwindcss/dist/base.min.css"
import "./src/css/index.css"
// import Layout from './src/components/Layout';
// 
// export const wrapPageElement = ({ element, props }) => (
//   <Layout {...props}>{element}</Layout>
// );
import React from "react"

import { ThemeProvider } from "./src/context/ThemeContext"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
